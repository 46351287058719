<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <edit-customer @hidden="selectedCustomer = undefined" id="edit-customer" :customer="selectedCustomer" :type="type" />
    <sticky-header :title="$route.meta.title">
      <template #buttons>
        <b-button class="ml-2" variant="primary" :to="{name: `${type}s.create`}"><fa-icon class="mr-2" icon="plus" />Create</b-button>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <b-row class="mb-3">
        <b-col cols="12">
          <b-form-input debounce="500" @update="load" placeholder="Search" type="text" v-model="query" />
        </b-col>
      </b-row>
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-row>
        <b-col cols="12">
          <b-list-group class="mb-4" v-if="!loading">
            <b-table sortable responsive outlined :items="formattedData" :fields="[{key: 'name', sortable: true}, {key: 'company', sortable: true}, {key: 'email', sortable: true}, 'hourly_fee', 'postcode', 'actions']">
              <template #cell(actions)="row">
                <div class="align-self-center d-flex flex-md-row flex-column">
                  <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="edit(row.item.id)" variant="primary" v-b-popover.top.hover="`Edit`"><fa-icon icon="pen" /></b-button>
                </div>
              </template>
            </b-table>
          </b-list-group>
          <b-pagination-nav :link-gen="paginationLinkGen" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import EditCustomer from './Modals/EditCustomer.vue'
import StickyHeader from '../components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'
import map from "lodash/map";

export default {
  components: { EditCustomer, StickyHeader },
  props: {
    type: { type: String, default: 'customer' },
  },
  data() {
    return {
      query: '',
      selectedCustomer: {},
      formattedData: []
    };
  },
  async mounted() {
    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    await this.load();
  },
  computed: {
    ...mapState('customers', ['data', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('customers', ['fetch']),
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: `${this.type}s` ?? 'customers', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: `${this.type}s` ?? 'customers', query: {...this.$route.params, page: pageNo}};
    },
    edit(id) {
      this.selectedCustomer = this.data.find(c => c.id === id);

      if (!(this.$route.name === `${this.type}s.update` && this.$route.params.customerId === id)) {
        this.$router.push({ name: `${this.type}s.update`, params: { customerId: id } });
      }
    },
    async load() {
      return await this.fetch({
        query: this.query,
        type: this.type,
        page: this.$route.query.page || 1
      });
    },
    displayMoney(money) {
      if (!money) return '£0.00';

      if (money !== Math.floor(money)) {
        return '£' + money.toFixed(2);
      } else {
        return '£' + money;
      }
    }
  },
  watch: {
    async type(newValue, value) {
      if (newValue === value) {
        return
      }

      await this.load()
    },
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.load();
      }

      return this.$bvModal.hide(value.meta.modalId);
    },

    data(data) {
      this.formattedData = map(data, customer => ({
        id: customer.id,
        name: customer.name,
        company: customer.company,
        email: customer.email,
        hourly_fee: this.displayMoney(customer.hourly_fee),
        postcode: customer.address ? customer.address.postcode : null
      }))
    }
  }
}
</script>

<style>

</style>
